import { Footer, Header } from "components";
import { About, Faq, Lander, Terms } from "pages";
import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useLocation,
} from "react-router-dom";

function App() {
    function Layout() {
        const location = useLocation();
        console.log(location);
        return (
            <div>
                <Header />
                <Outlet />
                {location.pathname !== "/" && (
                    <div className="flex justify-center p-4">
                        <Footer />
                    </div>
                )}
            </div>
        );
    }

    const router = createBrowserRouter([
        {
            element: <Layout />,
            children: [
                {
                    path: "/",
                    element: <Lander />,
                },
                {
                    path: "/about",
                    element: <About />,
                },
                {
                    path: "/tos",
                    element: <Terms />,
                },
                {
                    path: "/faq",
                    element: <Faq />,
                },
            ],
        },
    ]);

    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
