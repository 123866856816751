export const content = {
    HeroSplashText: (
        <>
            <span className="text-remiRed">R3M1</span> is a unique on-chain
            digital collection consisting of 6000 robots that exist solely
            within the ethereum network. These robots, powered by blockchain
            technology, are designed to be stored transparently and be highly
            accessible due to their decentralized nature and on-chain storage
            mechanism.
        </>
    ),
    BaseModelText: (
        <>
            <span className="text-remiOrange">The Base Models</span> are the The
            foundation of <span className="text-remiRed">R3M1</span> and will
            serve as the building blocks of the entire collection. The bases
            will be manufactured in a range of multiple colors, with some bases
            even featuring rare and unique color options. They will be available
            through <span className="text-remiOrange">The Base Mint</span> which
            will be the first large and anticipated event in the production
            schedule as noted in the{" "}
            <span className="text-remiRed">R3MI Roadmap</span> below.
        </>
    ),
    AccessoryText: (
        <>
            Once the bases have been manufactured, focus on{" "}
            <span className="text-remiOrange">Accessory</span> development will
            begin. <span className="text-remiOrange">Accessories</span> will be
            used to personalize your <span className="text-remiRed">R3M1</span>.
            Each base will be able to be equipped with multiple unique artistic
            pieces including expressions, handhelds, hats, glasses, backgrounds,
            and in rare instances, even pets, to make your{" "}
            <span className="text-remiRed">R3M1</span> unique.
        </>
    ),
    FAQContent: {
        questions: [
            "1. What is R3M1?",
            "2: How do you mint a R3M1?",
            "3: How do you pronounce R3M1?",
            "4: Are any R3M1s more rare than others?",
            "5: Are you partenering with other projects?",
            "6: Are you looking for additional developers, artist, etc?",
            "7: Who is the artist?",
        ],
        answers: [
            "R3M1 is a Ethereum NFT collection that is entirely on-chain and features various base model droids that you can add a one time customization of traits/attributes to.",
            "Base models will be available for mint on our website. Traits/Attributes will be blind auctioned in batches soon after the initial base mint has concluded.",
            "It is pronounced 'Remi' (REH-mee).",
            "There is rarity in the base model types and colors, as well as the traits which can be applied to them.",
            "At this time we are not partnering with other projects.",
            "We are not looking for additional personnel of any kind at this point.",
            "The arist working with us has elected to remain anonymous due to the negative aura of NFTs in their line of work as an illustrator",
        ],
    },
    About: (
        <>
            <h2 className="text-2xl text-remiGreen">
                So what is "<span className="text-remiRed">R3M1</span>"?
            </h2>{" "}
            <br />
            <span className="text-remiRed">R3M1</span> is an upcoming digital
            collection buy on-chain enthusiasts{" "}
            <span className="text-remiGreen">@Cr0wn_Gh0ul</span> and{" "}
            <span className="text-remiGreen">@CatThatPrograms</span>
            <br />
            <br />
            The mission of <span className="text-remiRed">R3M1</span> is to
            establish a dynamic and thriving community of on-chain collectors,
            who can utilize their robot companions in a multitude of ways. By
            fostering this community, <span className="text-remiRed">R3M1</span>{" "}
            aims to create an interactive and engaging platform that inspires
            creativity, innovation, and collaboration among its members.
            <br /> <br />
            The foundation of <span className="text-remiRed">R3M1</span> is
            comprised of the <span className="text-remiGreen">Base Models</span>
            , which will serve as the building blocks of the entire collection.
            These bases will be manufactured in a range of multiple colors, with
            some bases even featuring rare and unique color options. The release
            of these bases will be a highly anticipated event, marking the first
            major production milestone as detailed in the{" "}
            <span className="text-remiCharcoal">R3MI Roadmap</span>.
            <br />
            <br />
            Following the <span className="text-remiGreen">Base Mint</span>, the
            next phase of <span className="text-remiRed">R3M1</span>'s
            development will involve the minting of{" "}
            <span className="text-remiGreen">Accessories</span>, such as hats,
            facial expressions, handhelds, and backgrounds. These{" "}
            <span className="text-remiGreen">Accessories</span> can be
            permanently combined with the{" "}
            <span className="text-remiRed">R3M1</span> base models, creating a
            wide variety of customizable and unique robots.
            <br /> <br />
            By minting{" "}
            <span className="text-remiGreen">
                <span className="text-remiGreen">Accessories</span>
            </span>{" "}
            separately from the base models,{" "}
            <span className="text-remiRed">R3M1</span> provides collectors with
            the flexibility to choose which{" "}
            <span className="text-remiGreen">Accessories</span> they want to add
            to their collection, without being locked into a predetermined set of{" "}
            <span className="text-remiGreen">Accessories</span>. This approach
            allows for greater creativity and individual expression among
            collectors, while also ensuring that each{" "}
            <span className="text-remiRed">R3M1</span> robot is truly
            one-of-a-kind.
            <br />
            <br />
            Overall, the release of both the{" "}
            <span className="text-remiGreen">Base Models</span> and{" "}
            <span className="text-remiGreen">Accessories</span> will mark a
            major milestone in the <span className="text-remiRed">R3M1</span>{" "}
            universe, providing collectors with a new exciting digital art
            collection.
        </>
    ),
};
