import MultiRemi from "assets/multi-remi.png";
import SmartContractRemi from "assets/solidity_remi.png";
import BaseMint from "assets/remi_a.png";
import AccessoryPile from "assets/accessory_pile.png";
import AccUIImg from "assets/full_r3m1.png";
import TheUnknown from "assets/unknown_remi.png";
import { ArrowBottomLeft, ArrowLongRight } from "components";

export function RemiRoadmap() {
    const RoadmapCircle = ({
        text,
        completed,
        inProgress,
        imgSrc,
        w,
        tOffset = 0,
    }) => (
        <div className="flex gap-4 flex-col justify-center items-center bg-remiPaperTeal">
            <div
                className={`relative overflow-hidden flex justify-center items-center rounded-full h-32 w-32 border-4 bg-remiPaperWhite shadow-md ${
                    completed
                        ? "border-remiGreen"
                        : inProgress
                        ? "border-orange-300"
                        : "border-remiRed"
                }`}
            >
                <img
                    alt="remi_roadmap_img"
                    style={{
                        maxWidth: `${w}px`,
                        position: "relative",
                        top: `${tOffset}px`,
                    }}
                    src={imgSrc}
                />
            </div>
            <h3
                className={`text-sm ${inProgress && "text-remiOrange"} ${
                    completed && "text-remiGreen"
                } ${!inProgress && !completed && "text-remiRed"} `}
            >
                {text}
            </h3>
        </div>
    );

    const ArrowIcon = ({ isRight, arrowColorClass }) => (
        <div className="flex text-lg justify-center items-center h-16 w-16">
            {isRight ? (
                <ArrowLongRight colorClass={arrowColorClass} />
            ) : (
                <ArrowBottomLeft colorClass={arrowColorClass} />
            )}
        </div>
    );

    return (
        <>
            <div className="flex justify-center mb-8">
                <h2 className="text-remiCharcoal text-2xl">R3M1 Roadmap</h2>
            </div>
            <div className="flex gap-4 ">
                <div className="flex flex-col gap-8">
                    <RoadmapCircle
                        completed
                        text="Community"
                        imgSrc={MultiRemi}
                        w={210}
                    />
                    <RoadmapCircle
                        text="Base Mint"
                        imgSrc={BaseMint}
                        w={120}
                        tOffset={25}
                    />
                    <RoadmapCircle text="ACC UI" imgSrc={AccUIImg} />
                </div>
                <div className="flex flex-col gap-8 mt-8">
                    <ArrowIcon isRight arrowColorClass="stroke-green-500" />
                    <ArrowIcon arrowColorClass="stroke-orange-500" />
                    <ArrowIcon isRight arrowColorClass="stroke-red-500" />
                    <ArrowIcon arrowColorClass="stroke-red-500" />
                    <ArrowIcon isRight arrowColorClass="stroke-red-500" />
                </div>
                <div className="flex flex-col gap-8">
                    <RoadmapCircle
                        text="Smart Contracts"
                        inProgress
                        imgSrc={SmartContractRemi}
                        w={180}
                        tOffset={35}
                    />
                    <RoadmapCircle
                        text="ACC Mint"
                        imgSrc={AccessoryPile}
                        w={150}
                    />
                    <RoadmapCircle
                        text="The Unknown"
                        imgSrc={TheUnknown}
                        tOffset={22}
                    />
                </div>
            </div>
        </>
    );
}
