export function Button({
    primary,
    secondary,
    children,
    disabled,
    className,
    fancy,
    ...props
}) {
    let btnClasses =
        "py-[10px] px-[24px] rounded-tl-[8px] rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px] transition-all";

    // Fancy
    btnClasses = fancy ? btnClasses + " rounded-tl-12 rounded-tr-12 rounded-br-24 rounded-bl-24" : btnClasses
    // Primary
    btnClasses =
        primary && !disabled
            ? btnClasses +
              " text-white font-bold bg-[#E19488] shadow-primaryButtonUnpress hover:bg-[#DD7466] active:shadow-primaryButtonPressed active:relative active:top-[1px]"
            : btnClasses;
    // If disabled
    btnClasses += " bg-[#D7CDCC] text-[#FFFBF2]";

    let classes = btnClasses + " " + className;
    return (
        <button className={classes} {...props}>
            {children}
        </button>
    );
}
