import React from "react";
import { content } from "content/content";
import { useSpring } from "@react-spring/web";
import RemiA from "assets/remi_a.png";
import RemiB from "assets/remi_b.png";
import RemiC from "assets/remi_c.png";
import RemiD from "assets/remi_d.png";
import RemiE from "assets/remi_e.png";
import RemiF from "assets/remi_f.png";
import { Conveyer } from "components/conveyer/Conveyer";

export function TheBaseModels() {
    return (
        <div className="flex flex-col items-center w-[100%] gap-6 ">
            <h2 className="text-remiOrange text-2xl">The Base Models</h2>
            <div className="justify-start items-start flex w-[100%">
                <RemisRow />
            </div>
        </div>
    );
}

function RemisRow() {
    const RemiImgs = [RemiA, RemiB, RemiC, RemiD, RemiE, RemiF];

    const remioffsetPercentage = 87;
    const [rightOffset, setRightOffset] = React.useState(-34);
    const [reverse, setReverse] = React.useState(false);

    const [rollerSpring, rollerApi] = useSpring(() => ({
        from: { transform: "rotate(0deg)" },
        to: { transform: "rotate(-360deg)" },
        loop: true,
        immediate: true,
    }));

    const getRemiRow = () =>
        RemiImgs.map((img) => (
            <img className="max-w-[120px]" alt="remi_bot" src={img} />
        ));

    const swipeConveyer = (e) => {
        console.log(e);
    };

    // Bump Left Up Every X Seconds
    React.useEffect(() => {
        // rollerApi.set({ transform: "rotate(0deg)" });
        const shiftRow = () => {
            rollerApi.start({
                to: {
                    transform: "rotate(0deg)",
                },
                from: {
                    transform: reverse ? "rotate(-360deg)" : "rotate(360deg)",
                },
                config: {
                    friction: 70,
                },
            });

            let rverse = reverse;

            // Reverse it
            if (rightOffset === 314) {
                rverse = true;
            }

            // Forwards
            if (rightOffset === 53) {
                rverse = false;
            }

            let newOffset = reverse
                ? rightOffset - remioffsetPercentage
                : rightOffset + remioffsetPercentage;
            setRightOffset(newOffset);
            setReverse(rverse);
        };
        setTimeout(shiftRow, 2800);
    }, [rightOffset, rollerApi, reverse]);

    return (
        <div
            className="flex flex-col items-end w-[360px] overflow-hidden"
            onTouchEnd={swipeConveyer}
        >
            <div className="relative w-full h-ful">
                <div className="relative flex gap-48">
                    <div
                        style={{
                            background:
                                "linear-gradient(90deg, #FBFFFD 70%, transparent)",
                        }}
                        className="h-full w-[25%] absolute top-0 left-0 z-20"
                    />
                    <div
                        style={{
                            background:
                                "linear-gradient(90deg, transparent, #FBFFFD 30% )",
                        }}
                        className="h-full w-[25%] absolute top-0 right-0 z-20"
                    />
                    <div
                        style={{
                            position: "relative",
                            right: `${rightOffset}%`,
                            transition: "all 2s ease",
                        }}
                        className="relative flex gap-48"
                    >
                        {getRemiRow()}
                    </div>
                </div>
            </div>
            <div className="flex w-full items-end justify-center">
                <Conveyer h="86px" w="100%" rollerSpring={rollerSpring} />
            </div>
        </div>
    );
}

export function BaseSplashText() {
    return (
        <div>
            <p className="text-lg">{content.BaseModelText}</p>
        </div>
    );
}
