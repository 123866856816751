import { animated, useInView } from "@react-spring/web";
import { content } from "content/content";
import { RemiRoadmap } from "pages/Lander/RemiRoadmap/RemiRoadmap";
import BannerImg from "assets/banner.png";

export function About() {
    const [ref, spring] = useInView(
        () => ({
            from: { opacity: 0, top: "0%" },
            to: { opacity: 1, top: "100%" },
        }),
        {
            once: true,
        }
    );

    const [ref2, spring2] = useInView(
        () => ({
            from: { opacity: 0, top: "100%" },
            to: { opacity: 1, top: "0%" },
            config: {
                friction: 8,
                tension: 15,
            },
        }),
        {
            once: true,
        }
    );

    const [ref3, spring3] = useInView(
        () => ({
            from: { opacity: .5, top: "-50px" },
            to: { opacity: 1, top: "0px " },
            config: {
                friction: 4,
                tension: 70,
            },
        }),
        {
            once: true,
        }
    );

    const RemiBanner = () => <img src={BannerImg} alt="RemiBanner" />;

    return (
        <div className="flex flex-col justify-center relative">
            <animated.div
                style={{ ...spring3 }}
                ref={ref3}
                className="mt-6 md:p-0 p-4 relative flex justify-center h-full w-full"
            >
                <div className="max-w-6xl bg-slate-100 relative border-2 shadow-md border-slate-600/75 overflow-clip rounded-lg">
                    <RemiBanner />
                </div>
            </animated.div>
            <animated.div
                ref={ref}
                style={{ ...spring }}
                className="flex flex-col items-center w-full p-4"
            >
                <div className="max-w-6xl text-lg">{content.About}</div>
            </animated.div>
            <div className="flex w-full justify-center items-center mt-6 mb-6">
                <animated.div ref={ref2} style={{ ...spring2 }}>
                    <RemiRoadmap />
                </animated.div>
            </div>
        </div>
    );
}
